<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
      <el-breadcrumb-item>企业预登记</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div class="title">
        <div class="line"></div>
        <div class="text">企业预登记</div>
      </div>
      <el-button type="primary" @click="add" >新增</el-button>
      <div class="table-box">
        <el-table v-loading="queryTable.loading" :data="queryTable.table" style="width: 100%" tooltip-effect="light">
          <el-table-column type="index" label="序号"> </el-table-column>
          <el-table-column prop="enterpriseName" label="企业名称" align="center"></el-table-column>
          <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
          <el-table-column prop="outputValue" label="产值" align="center"></el-table-column>
          <el-table-column prop="referrer" label="引荐人" align="center"></el-table-column>
          <el-table-column prop="linkMan" label="联系人" align="center"></el-table-column>
          <el-table-column prop="linkManPhone" label="联系电话" align="center"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button @click="look(scope.row.id)" style="color: #135694" type="text">编辑</el-button>
              <!-- <el-button @click="del(scope.row.id)" style="color: #135694" type="text">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top: 25px">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :page-size="queryTable.pager.size"
            :total="queryTable.pager.total"
            :current-page.sync="queryTable.pager.page"
          ></el-pagination>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { QueryTable } from '@/assets/js/QueryTable';
import ListQuerytor from '@/common/ListQuerytorTs';
export default {
  name: 'policyService',
  data() {
    return {
      queryTable:  new ListQuerytor({api:this.$api.findExactAlternativeEnterpriseLibraryPage}) // new QueryTable({ type: 2 }, {}, this.$api.findExactAlternativeEnterpriseLibraryPage,{size:10}),
    };
  },
  methods: {
    add() {
      this.$router.push({ name: 'preRegistrationDetail', params: { id: 0 } });
    },
    look(id) {
      this.$router.push({ name: 'preRegistrationDetail', params: { id } });
    },
    del(id) {
      this.$confirm(`确定删除此数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$api.deleteExactAlternativeEnterpriseLibraryById({ id: id }).then((res) => {
          if (res.success) {
            this.$message.success({ message: '删除成功', offset: 80 });
            this.queryTable.query();
          } else {
            this.$message.error({ message: res.message, offset: 80 });
          }
        });
      });
    },
  },
  created() {
    this.queryTable.query();
  },
};
</script>

<style lang='scss' scoped>
.box {
  .content {
    padding: 20px;
    background-color: #fff;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    .table-box {
      margin-top: 20px;
    }
  }
}
</style>
